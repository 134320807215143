import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

// structured data for SEO

@Component({
  standalone: true,
  selector: 'app-json-ld',
  template: '<div [innerHTML]="html"></div>',
})
export class JsonLDComponent implements OnChanges {
  @Input() jsonLD: {[key: string]: any}[] = [];
  html: SafeHtml = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.html = this.getSafeHtml(changes['jsonLD'].currentValue);
  }

  getSafeHtml(jsonLD: {[key: string]: any}[]): SafeHtml {
    // escape / to prevent script tag in JSON
    const json = jsonLD ? JSON.stringify(jsonLD).replace(/\//g, '\\/') : '';
    const html = `<script type="application/ld+json">${json}</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
